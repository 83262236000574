(function () {
  'use strict';

  angular
  .module('neo.home.user')
  .config(config);

  function config($stateProvider) {
    $stateProvider
    .state('home.changePassword', {
      url: '^/change-password',
      templateUrl: 'home/user/change-password.tpl.html',
      controller: 'ChangePasswordCtrl',
      controllerAs: 'vm'
    })
    .state('home.changeUsername', {
      url: '^/change-username',
      templateUrl: 'home/user/change-username.tpl.html',
      controller: 'ChangeUsernameCtrl',
      controllerAs: 'vm'
    });
  }
}());
